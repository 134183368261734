import { Layout, Menu } from "antd";
import { useState } from "react";

import "./styles.css";

import SideNav from "../SideNav";
import Dashboard from "../Dashboard";
import AddContent from "../AddContent";
import ManageArtists from "../ManageArtists";
import ManageMediaHouses from "../ManageMediaHouses";
import ManagePlans from "../ManagePlans";
import ManangeMovies from "../ManageMovies";
import ManageGenres from "../ManageGenres";
import ManageUsers from "../ManageUsers";
import ManageSeries from "../ManageSeries";
import ManageEpisodes from "../ManageEpisodes";
import ManageOrders from "../ManageOrders";
import ManageAlbums from "../ManageAlbums";
import ManageVideos from "../ManageVideos";
import ManagePlaylists from "../ManagePlaylists";
import ManageDiscounts from "../ManageDiscounts";
import ManageLanguages from "../ManageLanguages";
import ManageEmployees from "../ManageEmployees";
import ManagePartners from "../ManagePartners";
import ManagePermissions from "../ManagePermissions";
import ManageTickets from "../ManageTickets";
import UploadContent from "../UploadContent";
import SubMonitor from "../Reports/subscripitonMonitor";
import UserReports from "../Reports/userReports";
import ContentReports from "../Reports/contentReports";
import ChurnReports from "../Reports/churnReports";
import LikeReports from "../Reports/likeReports";
import ManageResellers from "../ManageResellers";
import ManageResellerPlans from "../ManageResellerPlans";
import Rewards from "../Reports/rewards";
import ManageVouchers from "../ManageVouchers";

const { Header, Content } = Layout;

const AdminModule = ({ userEmail, initialTab }) => {
  const [selectedTab, setSelectedTab] = useState(initialTab);

  const navTo = (tab) => {
    sessionStorage.setItem('unpublished', 'yes')
    setSelectedTab(tab)
  }

  const content = {
    dashboard: <Dashboard navTo={navTo} />,
    playlists: <ManagePlaylists />,
    "add-content": <AddContent />,
    "upload-content": <UploadContent />,
    "manage-artists": <ManageArtists />,
    "manage-media-houses": <ManageMediaHouses />,
    "manage-plans": <ManagePlans />,
    "manage-movies": <ManangeMovies />,
    "manage-genres": <ManageGenres />,
    "manage-users": <ManageUsers />,
    "manage-series": <ManageSeries />,
    "manage-episodes": <ManageEpisodes />,
    "manage-orders": <ManageOrders />,
    "manage-albums": <ManageAlbums />,
    "manage-videos": <ManageVideos />,
    "manage-discounts": <ManageDiscounts />,
    "manage-languages": <ManageLanguages />,
    "manage-employees": <ManageEmployees />,
    "manage-permissions": <ManagePermissions />,
    "manage-tickets": <ManageTickets />,
    "manage-partners": <ManagePartners />,
    "subscription-monitor": <SubMonitor />,
    "user-reports": <UserReports />,
    "rewards": <Rewards />,
    "content-reports": <ContentReports />,
    "churn-reports": <ChurnReports />,
    "like-reports": <LikeReports />,
    "manage-resellers": <ManageResellers />,
    "manage-reseller-plans": <ManageResellerPlans />,
    "manage-vouchers": <ManageVouchers />
  };

  const logout = async () => {
    await sessionStorage.removeItem("access_token");
    await sessionStorage.removeItem("superadmin");
    await sessionStorage.removeItem("user_email");
    await sessionStorage.removeItem("lastNav");
    window.location.reload();
  };

  const updateTab = (tab) => {
    sessionStorage.removeItem('unpublished')
    setSelectedTab(tab);
    sessionStorage.setItem("lastNav", tab);
  };

  return (
    <>
      <Layout>
        <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <div className="header-content">
            <img
              src="/images/logo.webp"
              alt="chanajor-logo"
              className="header-logo"
            />
            <Menu theme="dark" mode="horizontal">
              <Menu.Item
                key="1"
                onClick={async () => await logout()}
                className="logout-btn"
              >
                Logout
              </Menu.Item>
            </Menu>
          </div>
        </Header>
        <Layout className="site-layout" style={{ marginTop: 64 }}>
          <SideNav updateTab={updateTab} userEmail={userEmail} />
          <Content>
            {content[selectedTab] ||
              "You do not have permissions to view this module"}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminModule;
