import React, { useContext, useState, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { Context } from '../../context';


const ReadCSV = (props) => {
    const { apiUrl, token } = useContext(Context)

    return (
        <Upload
            accept='text/csv'
            action={`${apiUrl}/vouchers/read_csv`}
            headers={{
                authorization: `Bearer ${token}`,
            }}
            onChange={(info) => {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                    let fieldName = props.id
                    props.form.setFieldsValue({ [String(fieldName)]: info.file.response.codes });
                    // console.log(props.form.getFieldValue(String(fieldName)))
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }}
            maxCount={1}
        >
            <Button icon={<UploadOutlined />}>Upload CSV</Button>
        </Upload >
    )
}

export default ReadCSV;