import { useState, useContext, useEffect } from 'react';
import {
  Row,
  Select,
  Table,
  DatePicker,
  Space,
  Modal,
  Form,
  Input,
  Upload,
  Radio,
  Button,
  Col,
  notification,
  InputNumber,
} from 'antd';
import {
  CloudDownloadOutlined,
  LoadingOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import UploadBanners from '../UploadBanners';
import ReadCSV from './readCSV';
import { Context } from "../../context";
import { Fetch, Post, Delete, Put } from "../../fetch";
import { convertIdToKey } from '../../utils';
import moment from 'moment';


const ManageVouchers = () => {
  const { token, apiUrl, transcodeUrl } = useContext(Context);

  const { Column } = Table;
  const { Option } = Select;
  // const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  const { confirm } = Modal;


  const [modalVisible, setModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [typeValue, setTypeValue] = useState('code');
  const [date, setDate] = useState();
  const [vouchers, setVouchers] = useState([]);
  const [filter, setFilter] = useState("all");
  const [voucher, setVoucher] = useState("");
  const [modalType, setModalType] = useState('Add');
  const [voucherValue, setVoucherValue] = useState(0);

  const [form] = Form.useForm();

  useEffect(() => {
    console.log(filter)
    Fetch(
      `${apiUrl}/vouchers?filter=${filter}`,
      token,
      (result) => {
        let v = convertIdToKey(result.vouchers)
        setVouchers(v)
      }
    )
  }, [apiUrl, token, filter, confirmLoading])


  //  On Submit Voucher
  const handleSubmit = async () => {
    setConfirmLoading(true)
    console.log('Voucher Form values - ', await form.validateFields());
    let values = await form.validateFields();
    let date = moment(form.getFieldValue('end_date')).format('YYYY-MM-DD')
    values = { ...values, end_date: date }
    let fn = voucher ? Put : Post
    console.log(fn)
    let url = voucher ? `${apiUrl}/vouchers/${voucher}` : `${apiUrl}/vouchers`
    try {
      fn(
        url,
        token,
        { ...values },
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: `Voucher Updated`
            })
            form.resetFields()
            setModalVisible(false)
            setConfirmLoading(false)
          }
        },
        () => {
          notification.error({
            message: `Add/Edit Voucher Error`,
            description: 'Check console for more details'
          })
        }
      )
    } catch (err) {
      console.error(err)
      notification.error({
        message: 'Add/Edit Voucher Error',
        description: 'Required fields are incorrect/missing'
      })
    } finally {
      setConfirmLoading(false)
      setModalVisible(false)
      form.resetFields()
    }
  };

  const showEditModal = (id) => {
    setModalType("Edit");
    console.log(id)
    setModalVisible(true);
    Fetch(`${apiUrl}/vouchers/${id}`, token, (result) => {
      let values = result.voucher
      values = { ...values, end_date: moment(result.voucher.end_date) }
      form.setFieldsValue(values);
      setVoucher(id);
    });
  };

  const confirmDelete = (id) => {
    confirm({
      title: "Are you sure you want to delete this Voucher?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setConfirmLoading(true)
        Delete(
          `${apiUrl}/vouchers/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: "Succes",
                description: "Voucher deleted successfully",
              });
            }
            setConfirmLoading(false)
          },
          (err) => {
            notification.error({
              message: "Error deleting series",
              description: "Please check console for more details",
            });
            setConfirmLoading(false)
          }
        );
      },
    });
  }
  return (
    <div className="manage-episodes-wrapper">
      <span style={{ paddingRight: '10px', display: 'inline-block' }}>
        Status:{' '}
      </span>
      <Select defaultValue={'all'} onChange={(_, e) => setFilter(e.value)}>
        <Option value="all">All</Option>
        <Option value="expired">Expired</Option>
        <Option value="active">Active</Option>
      </Select>
      <Table style={{ marginTop: '30px' }} dataSource={vouchers}>
        <Column title="Vendor Name" dataIndex="vendorName" key="vendorName" align="left" />
        <Column
          title="Voucher Value"
          dataIndex="value"
          key="value"
          align="left"
        />
        <Column
          title="Description"
          dataIndex="vendorDescription"
          key="vendorDescription"
          align="left"
        />
        <Column
          title="Valid Till"
          dataIndex="end_date"
          key="end_date"
          align="left"
          render={(_, record) => moment(record.end_date).format('YYYY-MM-DD')}
        />
        <Column title="Status" dataIndex="status" key="status" align="center" />
        <Column
          title="Actions"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => showEditModal(record.key)}>Edit</a>
              <a onClick={() => confirmDelete(record.key)}>Delete</a>
            </Space>
          )}
        />
      </Table>

      <PlusCircleTwoTone
        twoToneColor="#FF0091"
        style={{ fontSize: '48px' }}
        className="add-artist-btn"
        onClick={() => setModalVisible(true)}
      />
      <CloudDownloadOutlined className="export-btn" />

      <Modal
        title={modalType + " Voucher"}
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form form={form} layout="vertical" initialValues={{ gender: 'M' }}>
          <Form.Item
            name="vendorName"
            label="Vendor Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Enter Voucher name" />
          </Form.Item>
          <Form.Item
            name="vendorDescription"
            label="Vendor Description"
            rules={[{ required: true }]}
          >
            <TextArea rows={4} placeholder="Enter description here"></TextArea>
          </Form.Item>

          <Form.Item name={'voucherType'} label='Type' initialValue={typeValue}>
            <Radio.Group onChange={(e) => { setTypeValue(e.target.value); setVoucherValue(0) }} defaultValue={'code'}>
              <Radio value="code">Single Code</Radio>
              <Radio value="bulk">Bulk Codes</Radio>
              {/* <Radio value="deal">Deal</Radio> */}
            </Radio.Group>
          </Form.Item>
          {typeValue === 'code' && (
            <Form.Item
              name="code"
              label="Enter Code"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter Code" />
            </Form.Item>
          )}
          {typeValue === 'bulk' && (
            <Form.Item
              name="bulkCodes"
              label="Upload Codes CSV"
              rules={[{ required: true }]}
            >
              <ReadCSV form={form} filedName={'bulkCodes'} />
            </Form.Item>
          )}
          {typeValue === 'deal' && (
            <Form.Item
              name="dealUrl"
              label="Deal URL"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter website url" />
            </Form.Item>
          )}
          <Form.Item
            name="value"
            label="Voucher Value"
            initialValue={0}
            rules={[{ required: true }]}
          >
            <InputNumber
              placeholder="Enter Voucher value"
              value={voucherValue}
              disabled={typeValue !== 'bulk' ? true : false}
              onChange={(e) => {
                setVoucherValue(e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item
            name="end_date"
            label="Valid Till"
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="voucherImage"
            label="Voucher Image"
            rules={[{ required: false }]}
          >
            <UploadBanners
              name={"voucherImage"}
              type="voucher"
              updateForm={form}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div >
  );
};

export default ManageVouchers;
