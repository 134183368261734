import { AudioTwoTone, ContactsTwoTone, ShoppingTwoTone, VideoCameraTwoTone } from '@ant-design/icons'
import { Row, Col, Card } from 'antd'
import { useEffect, useContext, useState } from 'react'

import { Context } from '../../context'
import { Fetch } from '../../fetch'

import './styles.css'

const Dashboard = ({ navTo }) => {
    const { token, apiUrl, superAdmin } = useContext(Context)
    const [subscribers, setSubscribers] = useState(0)
    const [ordersMTD, setOrdersMTD] = useState(0)
    const [moviesAndSeries, setMoviesAndSeries] = useState(0)
    const [albums, setAlbums] = useState(0)
    const [unpublishedMovies, setUnpublishedMovies] = useState(0)
    const [unpublishedSeries, setUnpublishedSeries] = useState(0)
    const [unpublishedVideos, setUnpublishedVideos] = useState(0)

    useEffect(() => {
        Fetch(`${apiUrl}/admins/dashboard`, token, (result) => {
            setSubscribers(result.subscribers)
            setOrdersMTD(result.ordersMTD)
            setMoviesAndSeries(result.moviesAndSeries)
            setAlbums(result.videos)
            setUnpublishedMovies(result.unpublishedMovies)
            setUnpublishedSeries(result.unpublishedSeries)
            setUnpublishedVideos(result.unpublishedVideos)
        })
    }, [apiUrl, token])

    return (
        <>
            <Row>
                <Col span={6}>
                    <Card className="info-card">
                        <ContactsTwoTone twoToneColor="#e65251" style={{ fontSize: '3em' }} />
                        <div className="info-card-metric">
                            <p>Total Subscribers</p>
                            <h3>{subscribers}</h3>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className="info-card">
                        <ShoppingTwoTone twoToneColor="#ffaf00" style={{ fontSize: '3em' }} />
                        <div className="info-card-metric">
                            <p>Orders (MTD)</p>
                            <h3>{ordersMTD}</h3>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className="info-card">
                        <VideoCameraTwoTone twoToneColor="#00ce68" style={{ fontSize: '3em' }} />
                        <div className="info-card-metric">
                            <p>Shows</p>
                            <h3>{moviesAndSeries}</h3>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className="info-card">
                        <AudioTwoTone twoToneColor="#8862e0" style={{ fontSize: '3em' }} />
                        <div className="info-card-metric">
                            <p>Videos</p>
                            <h3>{albums}</h3>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className="info-card">
                        <VideoCameraTwoTone twoToneColor="#e65251" style={{ fontSize: '3em' }} />
                        <div className="info-card-metric">
                            <p>Unpublished Shows</p>
                            <h3><a onClick={() => navTo('manage-series')}>{unpublishedSeries}</a></h3>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className="info-card">
                        <AudioTwoTone twoToneColor="#8862e0" style={{ fontSize: '3em' }} />
                        <div className="info-card-metric">
                            <p>Unpublished Videos</p>
                            <h3><a onClick={() => navTo('manage-songs')}>{unpublishedVideos}</a></h3>
                        </div>
                    </Card>
                </Col>
            </Row>
            {superAdmin && <Row>
                {/* <Col span={6}>
                    <Card className="info-card">
                        <VideoCameraTwoTone twoToneColor="#00ce68" style={{ fontSize: '3em' }} />
                        <div className="info-card-metric">
                            <p>Unpublished Movies</p>
                            <h3><a onClick={() => navTo('manage-movies')}>{unpublishedMovies}</a></h3>
                        </div>
                    </Card>
                </Col> */}

            </Row>}
        </>
    )
}

export default Dashboard
