import { Form, Input, Modal, Table, Radio, Select, notification } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { PlusCircleTwoTone, CloudDownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import './styles.css'

import { Context } from '../../context'
import { Fetch, Post, Delete } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'

const { Column } = Table
const { Option } = Select
const { confirm } = Modal

const ManageMediaHouses = () => {
  const { token, apiUrl } = useContext(Context)

  const [mediaHouses, setMediaHouses] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [mediaHousesMaster, setMediaHousesMaster] = useState([])
  const [mhType, setMhType] = useState("Director")

  const [form] = Form.useForm()

  useEffect(() => {
    Fetch(`${apiUrl}/mediaHouses`, token, (result) => {
      const directors = convertIdToKey(result.directors)
      const producers = convertIdToKey(result.producers)
      const singers = convertIdToKey(result.singers)
      const lyricists = convertIdToKey(result.lyricists)
      setMediaHouses([...directors, ...producers, ...singers, ...lyricists])
      setMediaHousesMaster([...directors, ...producers, ...singers, ...lyricists])
    })
  }, [apiUrl, token, confirmLoading])

  const handleSubmit = async () => {
    try {
      setConfirmLoading(true)
      await form.validateFields()
      Post(
        `${apiUrl}/mediaHouses`,
        token,
        {
          name: form.getFieldValue('name'),
          mhType
        },
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: 'Media House created'
            })
          }
          form.resetFields()
          setModalVisible(false)
        },
        () => {
          notification.error({
            message: 'Add Media House Error',
            description: 'Check console for more details'
          })
        }
      )
    } catch (err) {
      console.error(err)
      notification.error({
        message: 'Add Media House Error',
        description: 'Required fields are incorrect/missing'
      })
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleDelete = async (id) => {
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        try {
          setConfirmLoading(true)
          Delete(
            `${apiUrl}/mediaHouses/${id}`,
            token,
            (result) => {
              if (result.success) {
                setConfirmLoading(false)
                notification.success({
                  message: 'Success',
                  description: 'Media House deleted successfully'
                })
              }
            },
            () => {
              setConfirmLoading(false)
              notification.error({
                message: 'Delete Media House Error',
                description: 'Check console for more details'
              })
            }
          )
        } catch (err) {
          console.log(err)
        }
      }
    })
  }

  const handleSearchChange = (mhId) => {
    if (!mhId)
      return

    setMediaHouses(mediaHousesMaster.filter(mh => mh.key === mhId))
  }

  return (
    <div className="manage-episodes-wrapper">
      <Select
        placeholder="Search Media House"
        showSearch
        allowClear
        onChange={handleSearchChange}
        onClear={() => setMediaHouses(mediaHousesMaster)}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        {mediaHousesMaster.map((mh) => (
          <Option value={mh.key}>{mh.name}</Option>
        ))}
      </Select>
      <Table dataSource={mediaHouses}>
        <Column title="Name" dataIndex="name" key="name" align="center" />
        <Column title="Type" dataIndex="mhType" key="mhType" align="center" />
        <Column
          title="Actions"
          key="action"
          align="center"
          render={(text, record) => (
            <a onClick={() => handleDelete(record.key)}>Delete</a>
          )}
        />
      </Table>
      <PlusCircleTwoTone
        twoToneColor="#FF0091"
        style={{ fontSize: '48px' }}
        className="add-media-house-btn"
        onClick={() => setModalVisible(true)}
      />
      <CloudDownloadOutlined
        className="export-btn"
        onClick={() => handleExportClick(apiUrl, token, 'media_houses')}
      />
      <Modal
        title="Add Media House"
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => setModalVisible(false)}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Radio.Group value={mhType} onChange={(e) => setMhType(e.target.value)}>
            <Radio value="Director">Director</Radio>
            <Radio value="Producer">Producer</Radio>
            <Radio value="Singer">Singer</Radio>
            <Radio value="Lyricist">Lyricist</Radio>
          </Radio.Group>
        </Form>
      </Modal>
    </div>
  );
}

export default ManageMediaHouses
