import {
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Radio,
  DatePicker,
  notification,
  Progress,
  Checkbox,
  Switch,
} from "antd";
import { useContext, useEffect, useState } from "react";
import {
  PlusCircleTwoTone,
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import * as tus from "tus-js-client";
import moment from "moment";

import "./styles.css";

import { Context } from "../../context";
import { Fetch, Post, Delete, Put } from "../../fetch";
import { convertIdToKey, handleExportClick, countryCodes } from "../../utils";
import TextArea from "antd/lib/input/TextArea";
import UploadBanners from "../UploadBanners/index";
import MultiCurrencyInput from "../MultiCurrencyInput/index";

const { Column } = Table;
const { Option } = Select;
const { confirm } = Modal;

const ManageSeries = () => {
  const { token, apiUrl, transcodeUrl } = useContext(Context);

  const [series, setSeries] = useState([]);
  const [genres, setGenres] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [actors, setActors] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [producers, setProducers] = useState([]);
  const [availability, setAvailability] = useState("perpetual");
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalType, setModalType] = useState("");
  const [seriesId, setSeriesId] = useState();
  const [counter, setCounter] = useState(0);
  const [trailerUploading, setTrailerUploading] = useState(false);
  const [popular, setPopular] = useState(false)
  const [trailerUploadProgress, setTrailerUploadProgress] = useState(0);
  const [trailer, setTrailer] = useState();
  const [seriesMaster, setSeriesMaster] = useState([]);
  const [monetizationModel, setMonetizationModel] = useState();
  const [featured, setFeatured] = useState(false);
  const [listType, setListType] = useState('allow_all');
  const [allowList, setAllowList] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    Fetch(`${apiUrl}/shows`, token, (result) => {
      const series = convertIdToKey(result.series);
      console.log("series_cms", series);

      series.forEach((su) => {
        if (!su.videoUploadDateTime) {
          su.videoUploadDateTime = "NA";
        } else {
          su.videoUploadDateTime = moment(su.videoUploadDateTime).format(
            "DD/MM/YYYY hh:mm A"
          );
        }
      });

      const unpublished = sessionStorage.getItem("unpublished");
      if (unpublished) {
        const result = series.filter((s) => s.availability === "unpublished");
        setSeries(result);
        setSeriesMaster(result);
      } else {
        setSeries(series);
        setSeriesMaster(series);
      }
    });

    Fetch(`${apiUrl}/genres`, token, (result) => {
      setGenres(result.genres);
    });

    Fetch(`${apiUrl}/languages`, token, (result) => {
      setLanguages(result.languages);
    });

    Fetch(`${apiUrl}/artists`, token, (result) => {
      setActors(result.artists);
    });

    Fetch(`${apiUrl}/mediaHouses`, token, (result) => {
      setDirectors(result.directors);
      setProducers(result.producers);
    });
  }, [apiUrl, token, counter]);

  const handleSubmit = async () => {
    if (modalType === "Edit") {
      setConfirmLoading(true);
      try {
        let fields = await form.validateFields();

        if (fields.startDate)
          fields["startDate"] = fields.startDate.toISOString(true);
        if (fields.endDate)
          fields["endDate"] = fields.endDate.toISOString(true);
        if (listType === 'allow') {
          for (const i in countryCodes) {
            console.log(fields)
            if (fields.countryMap.includes(i)) allowList.push(i)
          }
          console.log(allowList)
        }
        if (listType === 'disallow') {
          for (const i in countryCodes) {
            if (!fields.countryMap.includes(i)) allowList.push(i)
          }
          console.log(allowList)
        }
        if (listType === 'allow_all') {
          for (const i in countryCodes) {
            allowList.push(i)
          }
        }
        Put(
          `${apiUrl}/shows/${seriesId}`,
          token,
          {
            ...fields,
            trailer,
            listType: listType,
            allowedCountries: allowList,
            featured: featured,
          },
          (result) => {
            if (result.success) {
              notification.success({
                message: "Success",
                description: "Shows updated",
              });
            }
            form.resetFields();
            setModalVisible(false);
            setConfirmLoading(false);
            setCounter(counter + 1);
            setAllowList([]);
          },
          () => {
            setConfirmLoading(false)
            notification.error({
              message: "Edit Shows Error",
              description: "Check console for more details",
            });
          }
        );
      } catch (err) {
        setConfirmLoading(false)
        notification.error({
          message: "Edit Shows Error",
          description: "Required fields are incorrect/missing",
        });
      }
      return;
    }

    try {
      setConfirmLoading(true);
      const fields = await form.validateFields();
      if (listType === 'allow') {
        for (const i in countryCodes) {
          console.log(fields)
          if (fields.countryMap.includes(i)) allowList.push(i)
        }
        console.log(allowList)
      }
      if (listType === 'disallow') {
        for (const i in countryCodes) {
          if (!fields.countryMap.includes(i)) allowList.push(i)
        }
        console.log(allowList)
      }
      if (listType === 'allow_all') {
        for (const i in countryCodes) {
          allowList.push(i)
        }
      }
      Post(
        `${apiUrl}/shows`,
        token,
        {
          ...fields,
          trailer,
          listType: listType,
          allowedCountries: allowList,
          featured: featured,
        },
        (result) => {
          if (result.success) {
            notification.success({
              message: "Success",
              description: "Show created",
            });
          }
          form.resetFields();
          setModalVisible(false);
          setCounter(counter + 1);
          setAllowList([]);
        },
        () => {
          setConfirmLoading(false)
          notification.error({
            message: "Add Shows Error",
            description: "Check console for more details",
          });
        }
      );
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Add Shows Error",
        description: "Required fields are incorrect/missing",
      });
    } finally {
      setConfirmLoading(false);
    }
  };

  const showEditModal = (id) => {
    setModalType("Edit");
    setModalVisible(true);
    Fetch(`${apiUrl}/shows/${id}?admin=${true}`, token, (result) => {
      const { startDate, endDate, videoUploadDateTime, ...others } = result;
      if (startDate && endDate) {
        form.setFieldsValue({
          ...others,
          startDate: moment(startDate),
          endDate: moment(endDate),
        });
      } else if (startDate) {
        form.setFieldsValue({ ...others, startDate: moment(startDate) });
      } else if (videoUploadDateTime) {
        form.setFieldsValue({
          ...others,
          videoUploadDateTime: moment(videoUploadDateTime),
        });
      } else {
        form.setFieldsValue(others);
      }
      let values = result.allowedCountries
      if (result.listType) {
        setListType(result.listType)
        form.setFieldValue('listType', result.listType)
      } else {
        setListType('allow_all')
        form.setFieldValue('listType', 'allow_all')
      }
      let countries = []
      if (result.listType === 'allow') {
        form.setFieldValue('countryMap', values)
      }
      if (result.listType === 'disallow') {
        for (const i in countryCodes) {
          if (!values.includes(i)) {
            countries.push(i)
          }
        }
        form.setFieldValue('countryMap', countries)
        countries = []
      }
      setSeriesId(id);
      setMonetizationModel(result.model);
      setFeatured(result.featured);
    });
  };

  const confirmDelete = (id) => {
    confirm({
      title: "Are you sure you want to delete this Show?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        Delete(
          `${apiUrl}/shows/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: "Succes",
                description: "Show deleted successfully",
              });
              setCounter(counter + 1);
            } else {
              const playlists = result.detail.playlists;
              const promos = result.detail.promos;

              if (playlists.length > 0 && promos.length > 0) {
                notification.error({
                  message: "Error deleting Show",
                  description: `Please delete from playlists [${playlists.join(
                    ", "
                  )}] and promos [${promos.join(", ")}] first`,
                });
              } else if (playlists.length > 0) {
                notification.error({
                  message: "Error deleting Show",
                  description: `Please delete from playlists [${playlists.join(
                    ", "
                  )}] first`,
                });
              } else if (promos.length > 0) {
                notification.error({
                  message: "Error deleting Show",
                  description: `Please delete from promos [${promos.join(
                    ", "
                  )}] first`,
                });
              }
            }
          },
          (err) => {
            notification.error({
              message: "Error deleting Show",
              description: "Please check console for more details",
            });
          }
        );
      },
    });
  };

  const handleChange = (e, type) => {
    const file = e.target.files[0];

    setTrailerUploading(true);

    const upload = new tus.Upload(file, {
      endpoint: `${transcodeUrl}/files`,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onError: (error) => {
        console.log("Failed because: " + error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setTrailerUploadProgress(percentage);
        console.log(bytesUploaded, bytesTotal, percentage + "%");
      },
      onSuccess: () => {
        if (type === "trailer") {
          Post(
            `${transcodeUrl}/submitJob`,
            token,
            {
              id: seriesId,
              loc: upload.url.split("/").slice(-1)[0],
              media_type: "series_trailer",
            },
            (result) => {
              console.log(result);
            },
            () => {
              console.error(`Error transcoding content`);
            }
          );
        }

        console.log("Download %s from %s", upload.file.name, upload.url);
      },
    });

    upload.start();
  };

  const handleSearchChange = (seriesId) => {
    Fetch(`${apiUrl}/shows/${seriesId}`, token, (result) => {
      const series = convertIdToKey([result]);
      series.forEach((s) => {
        if (typeof s["genre"] === "string") {
          s["genre"] = [s["genre"]];
        }

        if (!s.videoUploadDateTime) {
          s.videoUploadDateTime = "NA";
        } else {
          s.videoUploadDateTime = moment(s.videoUploadDateTime).format(
            "DD/MM/YYYY hh:mm A"
          );
        }
        if (s.bannerImage) s.bannerImage = s.bannerImage + '/desktopBanner';
        if (s.cardImage) s.cardImage = s.cardImage + '/public';
      });
      setSeries(series);
    });
  };

  const handleFeaturedToggle = (checked) => {
    setFeatured(checked);
  }

  return (
    <div className="manage-episodes-wrapper">
      <Select
        placeholder="Search Shows"
        showSearch
        allowClear
        onChange={handleSearchChange}
        onClear={() => setSeries(seriesMaster)}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        {seriesMaster.map((s) => (
          <Option value={s.key}>{s.title}</Option>
        ))}
      </Select>
      <Table dataSource={series}>
        <Column title="Name" dataIndex="title" key="title" align="center" />
        <Column
          title="Featured"
          key="featured"
          align="center"
          render={(_, record) =>
            record.featured ? <CheckCircleFilled /> : <CloseCircleOutlined />
          }
        />
        <Column
          title="Genres"
          dataIndex="genre"
          key="genre"
          align="center"
          render={(genre) => (
            <>
              {genre.map((g) => (
                <Tag color="blue" key={g}>
                  {g}
                </Tag>
              ))}
            </>
          )}
        />
        <Column
          title="Availability"
          dataIndex="availability"
          key="availability"
          align="center"
        />
        <Column title="Model" dataIndex="model" key="model" align="center" />
        <Column
          title="Trailer Exists"
          key="trailer"
          align="center"
          render={(_, record) =>
            record.trailer ? <CheckCircleFilled /> : <CloseCircleOutlined />
          }
        />
        <Column
          title="Video Uploaded Date"
          dataIndex="videoUploadDateTime"
          key="videoUploadDateTime"
          align="center"
        />
        <Column
          title="Actions"
          key="actions"
          align="center"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => showEditModal(record.key)}>Edit</a>
              <a onClick={() => confirmDelete(record.key)}>Delete</a>
            </Space>
          )}
        />
      </Table>
      <PlusCircleTwoTone
        twoToneColor="#FF0091"
        style={{ fontSize: "48px" }}
        className="add-series-btn"
        onClick={() => {
          setModalVisible(true);
          setModalType("Add");
        }}
      />
      <CloudDownloadOutlined
        className="export-btn"
        onClick={() => handleExportClick(apiUrl, token, "series")}
      />
      <Modal
        title={modalType + " Show"}
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
          setAllowList([]);
        }}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ availability: "perpetual" }}
        >
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="slug"
            label="Slug in Url"
            rules={[
              { required: true },
              {
                pattern: new RegExp(/^[a-zA-Z0-9\-]+$/),
                message: "Please enter alphanumeric or - only",
              },
            ]}
          >
            <Input
              size="middle"
              placeholder="Enter only alphanumeric or -"
              disabled={(modalType !== "Edit") ? false :
                (availability === "unpublished") ? false : true}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="language"
            label="Language"
            rules={[{ required: true }]}
          >
            <Select mode="multiple" placeholder="Choose a language" showSearch>
              {languages.map((language) => (
                <Option value={language.name}>{language.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="metaTitle"
            label="metaTitle"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="metaDescription"
            label="metaDescription"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="metaKeywords"
            label="metaKeywords"
            rules={[{ required: true }]}
          >
            <Select
              mode="tags"
              style={{
                width: "100%",
              }}
              tokenSeparators={["Enter"]}
            ></Select>
          </Form.Item>
          <Form.Item
            name="genre"
            label="Genre / Category"
            rules={[{ required: true }]}
          >
            <Select placeholder="Choose a genre" mode="multiple" showSearch>
              {genres.map((genre) => (
                <Option value={genre.name}>{genre.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="maturity"
            label="Maturity"
            rules={[{ required: true }]}
          >
            <Select placeholder="Choose maturity">
              <Option value="U">U (Universal)</Option>
              <Option value="U/A 7+">U/A 7+ (For Ages 7 and Above)</Option>
              <Option value="U/A 13+">U/A 13+ (For Ages 13 and Above)</Option>
              <Option value="U/A 16+">U/A 16+ (For Ages 16 and Above)</Option>
              <Option value="A">A (Adults - Ages 18 and Above)</Option>
            </Select>
          </Form.Item>
          <Form.Item name="actors" label="Actors" rules={[{ required: true }]}>
            <Select showSearch mode="multiple" className="form-card-selector">
              {actors.map((actor) => (
                <Option key={actor.key} value={actor.name}>
                  {actor.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="directors"
            label="Directors"
            rules={[{ required: true }]}
          >
            <Select showSearch mode="multiple" className="form-card-selector">
              {directors.map((director) => (
                <Option key={director.key} value={director.name}>
                  {director.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="producers"
            label="Producers"
            rules={[{ required: true }]}
          >
            <Select showSearch mode="multiple">
              {producers.map((producer) => (
                <Option key={producer.key} value={producer.name}>
                  {producer.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="featured"
            label="Featured?"
          >
            <Switch checked={featured} onChange={handleFeaturedToggle} />
          </Form.Item>
          <Form.Item
            name="availability"
            label="Availability"
            rules={[{ required: true }]}
          >
            <Radio.Group onChange={(e) => setAvailability(e.target.value)}>
              <Radio value="perpetual">Perpetual</Radio>
              <Radio value="restricted">Restricted</Radio>
              <Radio value="unpublished">Unpublished</Radio>
            </Radio.Group>
          </Form.Item>
          {availability === "restricted" && (
            <>
              <Form.Item
                name="startDate"
                label="Start Date"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
              </Form.Item>
              <Form.Item
                name="endDate"
                label="End Date"
                className="form-item-selector"
                format="YYYY-MM-DD HH:mm:ss"
              >
                <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
              </Form.Item>
            </>
          )}
          <Form.Item name='listType' label={'Content access'} rules={[{ 'required': true }]} initialValue={listType}>
            <Radio.Group
              className="content-type-option"
              value={listType}
              onChange={(e) => setListType(e.target.value)}
              defaultValue={'allow_all'}
            >
              <Radio value="allow_all">Allow Globally</Radio>
              <Radio value="allow">Allow List</Radio>
              <Radio value="disallow">Deny List</Radio>
            </Radio.Group>
          </Form.Item>

          {['allow', 'disallow'].includes(listType) && (
            <Form.Item
              name={`countryMap`}
              label={'Countries'}
              rules={[{ required: true }]}
              style={{ width: '100%' }}
            >
              <Select
                showSearch
                mode="multiple"
                className="form-card-selector"
                style={{ width: '100%' }}
              >
                {Object.entries(countryCodes).map(([code, name]) => (
                  <Option key={code} value={code} >
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>)}
          <Form.Item label="Model" name="model" rules={[{ required: true }]}>
            <Radio.Group onChange={(e) => setMonetizationModel(e.target.value)}>
              {/* <Radio value="ticket">Ticket</Radio> */}
              <Radio value="subscription">Subscription</Radio>
              <Radio value="free">Free</Radio>
            </Radio.Group>
          </Form.Item>
          {/* {monetizationModel === "ticket" && (
            <>
              <Form.Item
                name="streamPeriod"
                label="Streaming Period"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={1}
                  max={30}
                  placeholder="Enter number of days (1-30)"
                />
              </Form.Item>
              <Form.Item
                name="subscriberPrice"
                label="Price for Subscribers"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <MultiCurrencyInput
                  form={form}
                  fieldName={"subscriberPrice"}
                ></MultiCurrencyInput>
              </Form.Item>
              <Form.Item
                name="nonSubscriberPrice"
                label="Price for Non Subscribers"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <MultiCurrencyInput
                  form={form}
                  fieldName={"nonSubscriberPrice"}
                ></MultiCurrencyInput>
              </Form.Item>
            </>
          )} */}
          <Form.Item
            name="cardImage"
            label="Card Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"cardImage"}
              type="series"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="detailImage"
            label="Detail Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"detailImage"}
              type="series"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="trailerImage"
            label="Wide Image (for continue watch in web)"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"trailerImage"}
              type="series"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="bannerImage"
            label="Banner Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"bannerImage"}
              type="series"
              updateForm={form}
            />
          </Form.Item>
          {/* <Form.Item
            name="logoImage"
            label="Banner Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"logoImage"}
              type="series"
              updateForm={form}
            />
          </Form.Item> */}
        </Form>
      </Modal>
    </div>
  );
};

export default ManageSeries;
