import {
  Space,
  Table,
  Tag,
  Modal,
  notification,
  Form,
  Input,
  InputNumber,
  Select,
  Card,
  Radio,
  DatePicker,
  Button,
  Switch,
  Popover,
  Checkbox,
} from "antd";
import {
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import * as tus from "tus-js-client";
import moment from "moment";

import "./styles.css";

import { Context } from "../../context";
import { Fetch, Put, Delete, Post } from "../../fetch";
import { convertIdToKey, handleExportClick } from "../../utils";
import UploadBanners from "../UploadBanners/index";
import MultiCurrencyInput from "../MultiCurrencyInput/index";

const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

const ManageMovies = () => {
  const { token, apiUrl, transcodeUrl, superAdmin } = useContext(Context);

  const [movies, setMovies] = useState([]);
  const [moviesMaster, setMoviesMaster] = useState([]);
  const [movieId, setMovieId] = useState();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [counter, setCounter] = useState(0);
  const [actors, setActors] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [producers, setProducers] = useState([]);
  const [genres, setGenres] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [availability, setAvailability] = useState("perpetual");
  const [monetizationModel, setMonetizationModel] = useState("");
  const [trailerUploading, setTrailerUploading] = useState(false);
  const [movieUploading, setMovieUploading] = useState(false);
  const [trailerUploadProgress, setTrailerUploadProgress] = useState(0);
  const [movieUploadProgress, setMovieUploadProgress] = useState(0);
  const [srt, setSrt] = useState();
  const [contentExists, setContentExists] = useState(false);
  const [trailerExists, setTrailerExists] = useState(false);
  const [subscriberPrice, setSubscriberPrice] = useState({ INR: 1 });
  const [nonSubscriberPrice, setNonSubscriberPrice] = useState({ INR: 1 });
  const [popular, setPopular] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    Fetch(`${apiUrl}/movies`, token, (result) => {
      const movies = convertIdToKey(result.movies);
      console.log("movie_cms", movies);
      movies.forEach((movie) => {
        if (typeof movie["genre"] === "string") {
          movie["genre"] = [movie["genre"]];
        }
        if (!movie['bannerImage']) {
          movie.bannerImage = ''
        }
        if (!movie['logoImage']) {
          movie.logoImage = ''
        }
        if (!movie.videoUploadDateTime) {
          movie.videoUploadDateTime = "NA";
        } else {
          movie.videoUploadDateTime = moment(movie.videoUploadDateTime).format(
            "DD/MM/YYYY hh:mm A"
          );
        }
      });
      const unpublished = sessionStorage.getItem('unpublished')
      if (unpublished) {
        const result = movies.filter(m => m.availability === 'unpublished')
        setMovies(result)
        setMoviesMaster(result)
      } else {
        setMovies(movies);
        setMoviesMaster(movies);
      }
    });

    Fetch(`${apiUrl}/genres`, token, (result) => {
      setGenres(result.genres);
    });

    Fetch(`${apiUrl}/languages`, token, (result) => {
      setLanguages(result.languages);
    });

    Fetch(`${apiUrl}/artists`, token, (result) => {
      setActors(result.artists);
    });

    Fetch(`${apiUrl}/mediaHouses`, token, (result) => {
      setDirectors(result.directors);
      setProducers(result.producers);
    });
  }, [apiUrl, token, counter]);

  useEffect(() => {
    if (!movieId) {
      return;
    }

    Fetch(`${apiUrl}/movies/${movieId}?admin=${true}`, token, (result) => {
      console.log(result)
      const { startDate, endDate, videoUploadDateTime, ...others } = result;
      if (startDate && endDate) {
        form.setFieldsValue({
          ...others,
          startDate: moment(startDate),
          endDate: moment(endDate),
        });
      } else if (startDate) {
        form.setFieldsValue({ ...others, startDate: moment(startDate) });
      } else if (videoUploadDateTime) {
        form.setFieldsValue({
          ...others,
          videoUploadDateTime: moment(videoUploadDateTime),
        });
      } else {
        form.setFieldsValue(others);
      }
      if (result.subscriberPrice) setSubscriberPrice(result.subscriberPrice);
      if (result.nonSubscriberPrice)
        setNonSubscriberPrice(result.nonSubscriberPrice);
      setAvailability(result.availability);
      setMonetizationModel(result.model);
      setContentExists(result.playbackUrl ? true : false);
      setTrailerExists(result.trailer ? true : false);
      if (result.popular)
        setPopular(result.popular)
    });
  }, [apiUrl, token, movieId]);

  const showConfirmDelete = (id) => {
    confirm({
      title: "Are you sure you want to delete this movie?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        Delete(
          `${apiUrl}/movies/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: "Succes",
                description: "Movie deleted successfully",
              });
              setCounter(counter + 1);
            } else {
              const playlists = result.detail.playlists;
              const promos = result.detail.promos;

              if (playlists.length > 0 && promos.length > 0) {
                notification.error({
                  message: "Error deleting series",
                  description: `Please delete from playlists [${playlists.join(
                    ", "
                  )}] and promos [${promos.join(", ")}] first`,
                });
              } else if (playlists.length > 0) {
                notification.error({
                  message: "Error deleting series",
                  description: `Please delete from playlists [${playlists.join(
                    ", "
                  )}] first`,
                });
              } else if (promos.length > 0) {
                notification.error({
                  message: "Error deleting series",
                  description: `Please delete from promos [${promos.join(
                    ", "
                  )}] first`,
                });
              }
            }
          },
          (err) => {
            if (err === "403") {
              notification.error({
                message: "Error deleting movie",
                description: "Please remove movie from banners/promos first",
              });
            } else {
              notification.error({
                message: "Error deleting movie",
                description: "Please check console for more details",
              });
            }
          }
        );
      },
    });
  };

  const handleEdit = async () => {
    try {
      let values = await form.validateFields();
      if (values.startDate)
        values["startDate"] = values.startDate.toISOString(true);
      if (values.endDate) values["endDate"] = values.endDate.toISOString(true);
      Put(
        `${apiUrl}/movies/${movieId}`,
        token,
        { ...values, popular: form.getFieldValue('popular') },
        (result) => {
          if (result.success) {
            notification.success({
              message: "Edited successfully",
            });
            form.resetFields();
            setEditModalVisible(false);
            setMovieId(null);
            setCounter(counter + 1);
          }
        },
        () => {
          notification.error({
            message: "Error updating movie",
            description: "Please check logs for more details",
          });
        }
      );
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Edit Movie Error",
        description: "Required fields are incorrect/missing",
      });
    }
  };

  const handleChange = (e, type) => {
    const file = e.target.files[0];
    let setUploadProgressFn;

    if (type === "movie") {
      setMovieUploading(true);
      setUploadProgressFn = setMovieUploadProgress;
    } else {
      setTrailerUploading(true);
      setUploadProgressFn = setTrailerUploadProgress;
    }
    const upload = new tus.Upload(file, {
      endpoint: `${transcodeUrl}/files`,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onError: (error) => {
        console.log("Failed because: " + error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setUploadProgressFn(percentage);
        console.log(bytesUploaded, bytesTotal, percentage + "%");
      },
      onSuccess: () => {
        if (type === "movie") {
          Post(
            `${transcodeUrl}/submitJob`,
            token,
            {
              id: movieId,
              loc: upload.url.split("/").slice(-1)[0],
              media_type: "movies",
            },
            (result) => {
              console.log(result);
            },
            () => {
              console.error(`Error transcoding content`);
            }
          );
        }

        if (type === "trailer") {
          Post(
            `${transcodeUrl}/submitJob`,
            token,
            {
              id: movieId,
              loc: upload.url.split("/").slice(-1)[0],
              media_type: "movie_trailer",
            },
            (result) => {
              console.log(result);
            },
            () => {
              console.error(`Error transcoding content`);
            }
          );
        }

        if (type === "srt") {
          setSrt(upload.url);
        }

        console.log("Download %s from %s", upload.file.name, upload.url);
      },
    });

    upload.start();
  };

  const handleSearchChange = (movieId) => {
    Fetch(`${apiUrl}/movies/${movieId}`, token, (result) => {
      const movies = convertIdToKey([result]);
      movies.forEach((movie) => {
        if (typeof movie["genre"] === "string") {
          movie["genre"] = [movie["genre"]];
        }

        if (!movie.videoUploadDateTime) {
          movie.videoUploadDateTime = "NA";
        } else {
          movie.videoUploadDateTime = moment(movie.videoUploadDateTime).format(
            "DD/MM/YYYY hh:mm A"
          );
        }
      });
      setMovies(movies);
    });
  };

  return (
    <div className="manage-movies-wrapper">
      <Select
        placeholder="Search Movie"
        showSearch
        allowClear
        onChange={handleSearchChange}
        onClear={() => setMovies(moviesMaster)}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        {moviesMaster.map((m) => (
          <Option value={m.key}>{m.title}</Option>
        ))}
      </Select>
      <Table dataSource={movies}>
        <Column title="Name" dataIndex="title" key="title" align="center" />
        <Column
          title="Language"
          dataIndex="language"
          key="language"
          align="center"
          render={(languages) => (
            <>
              {languages.map((language) => (
                <Tag color="blue" key={language}>
                  {language}
                </Tag>
              ))}
            </>
          )}
        />
        <Column
          title="Genres"
          dataIndex="genre"
          key="genre"
          align="center"
          render={(genre) => (
            <>
              {genre.map((g) => (
                <Tag color="blue" key={g}>
                  {g}
                </Tag>
              ))}
            </>
          )}
        />
        <Column
          title="Availability"
          dataIndex="availability"
          key="availability"
          align="center"
        />
        <Column title="Model" dataIndex="model" key="model" align="center" />
        <Column
          title="Trailer Exists"
          key="trailer"
          align="center"
          render={(_, record) =>
            record.trailer ? <CheckCircleFilled /> : <CloseCircleOutlined />
          }
        />
        <Column
          title="Content Exists"
          key="playbackUrl"
          align="center"
          render={(_, record) =>
            record.playbackUrl ? <CheckCircleFilled /> : <CloseCircleOutlined />
          }
        />
        <Column
          title="Subtitles Exists"
          key="subtitles"
          align="center"
          render={(_, record) =>
            record.subtitles ? <CheckCircleFilled /> : <CloseCircleOutlined />
          }
        />

        <Column
          title="Video Upload Date"
          dataIndex="videoUploadDateTime"
          key="videoUploadDateTime"
          align="center"
        />
        <Column
          title="Actions"
          key="action"
          align="center"
          render={(text, record) => {
            if (superAdmin || record.availability === "unpublished") {
              return (
                <Space size="middle">
                  {record.comments ? (
                    <Popover
                      content={record.comments}
                      title="Admin Comments"
                      trigger="click"
                    >
                      <Button>View Comments</Button>
                    </Popover>
                  ) : null}
                  <Button
                    onClick={() => {
                      setMovieId(record.key);
                      setEditModalVisible(true);
                      setTrailerUploading(false);
                      setTrailerUploadProgress(0);
                      setMovieUploading(false);
                      setMovieUploadProgress(0);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      showConfirmDelete(record.key);
                    }}
                  >
                    Delete
                  </Button>
                </Space>
              );
            } else {
              return "-";
            }
          }}
        />
      </Table>
      <CloudDownloadOutlined
        className="export-btn-only"
        onClick={() => handleExportClick(apiUrl, token, "movies")}
      />
      <Modal
        title="Edit Movie"
        visible={editModalVisible}
        okText="Update"
        onOk={handleEdit}
        onCancel={() => setEditModalVisible(false)}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input size="middle" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="slug"
            label="Slug in Url"
            rules={[
              { required: true },
              {
                pattern: new RegExp(/^[a-zA-Z0-9\-]+$/),
                message: "Please enter alphanumeric or - only",
              },
            ]}
          >
            <Input
              size="middle"
              placeholder="Enter only alphanumeric or -"
              disabled={availability !== "unpublished" ? true : false}
            />
          </Form.Item>
          <Form.Item
            name="language"
            label="Language"
            rules={[{ required: true }]}
          >
            <Select placeholder="Choose a language" showSearch mode="multiple">
              {languages.map((language) => (
                <Option value={language.name}>{language.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="genre"
            label="Genre / Category"
            rules={[{ required: true }]}
          >
            <Select placeholder="Choose a genre" mode="multiple" showSearch>
              {genres.map((genre) => (
                <Option value={genre.name}>{genre.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="maturity"
            label="Maturity"
            rules={[{ required: true }]}
          >
            <Select placeholder="Choose maturity">
              <Option value="U">U (Universal)</Option>
              <Option value="U/A 7+">U/A 7+ (For Ages 7 and Above)</Option>
              <Option value="U/A 13+">U/A 13+ (For Ages 13 and Above)</Option>
              <Option value="U/A 16+">U/A 16+ (For Ages 16 and Above)</Option>
              <Option value="A">A (Adults - Ages 18 and Above)</Option>
            </Select>
          </Form.Item>
          <Form.Item name="year" label="Year" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="metaTitle"
            label="metaTitle"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="metaDescription"
            label="metaDescription"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="metaKeywords"
            label="metaKeywords"
            rules={[{ required: true }]}
          >
            <Select
              mode="tags"
              style={{
                width: "100%",
              }}
              tokenSeparators={["Enter"]}
            ></Select>
          </Form.Item>
          <Card title="Cast &amp; Crew" className="form-card">
            <Form.Item
              name="actors"
              label="Actors"
              className="form-item-selector"
              rules={[{ required: true }]}
            >
              <Select showSearch mode="multiple" className="form-card-selector">
                {actors.map((actor) => (
                  <Option key={actor.key} value={actor.name}>
                    {actor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="directors"
              label="Directors"
              className="form-item-selector"
              rules={[{ required: true }]}
            >
              <Select showSearch mode="multiple" className="form-card-selector">
                {directors.map((director) => (
                  <Option key={director.key} value={director.name}>
                    {director.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="producers"
              label="Producers"
              className="form-item-selector"
              rules={[{ required: true }]}
            >
              <Select showSearch mode="multiple">
                {producers.map((producer) => (
                  <Option key={producer.key} value={producer.name}>
                    {producer.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Card>
          {superAdmin && (
            <Card title="Availability" className="form-card">
              <Form.Item
                name="availability"
                label="Availability"
                rules={[{ required: true }]}
              >
                <Radio.Group onChange={(e) => setAvailability(e.target.value)}>
                  <Radio value="perpetual">Perpetual</Radio>
                  <Radio value="restricted">Restricted</Radio>
                  <Radio value="unpublished">Unpublished</Radio>
                </Radio.Group>
              </Form.Item>
              {availability === "restricted" && (
                <>
                  <Form.Item
                    name="startDate"
                    label="Start Date"
                    className="form-item-selector"
                    rules={[{ required: true }]}
                  >
                    <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                  </Form.Item>
                  <Form.Item
                    name="endDate"
                    label="End Date"
                    className="form-item-selector"
                    format="YYYY-MM-DD HH:mm:ss"
                  >
                    <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                  </Form.Item>
                </>
              )}
            </Card>
          )}
          <Form.Item
            name="cardImage"
            label="Card Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"cardImageMovie"}
              type="movie"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="detailImage"
            label="Detail Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"detailImage"}
              type="movie"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="trailerImage"
            label="Trailer Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"trailerImage"}
              type="movie"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="bannerImage"
            label="Banner Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"bannerImage"}
              type="movie"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="logoImage"
            label="Logo Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"logoImage"}
              type="movie"
              updateForm={form}
            />
          </Form.Item>
          <Card title="Monetization" className="form-card">
            <Form.Item label="Model" name="model">
              <Radio.Group
                onChange={(e) => setMonetizationModel(e.target.value)}
              >
                {/* <Radio value="ticket">Ticket</Radio> */}
                <Radio value="subscription">Subscription</Radio>
                <Radio value="free">Free</Radio>
              </Radio.Group>
            </Form.Item>
            {monetizationModel === "ticket" && (
              <>
                <Form.Item
                  name="streamPeriod"
                  label="Streaming Period"
                  className="form-item-selector"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={1}
                    max={30}
                    placeholder="Enter number of days (1-30)"
                  />
                </Form.Item>
                <Form.Item
                  name="subscriberPrice"
                  label="Price for Subscribers"
                  className="form-item-selector"
                  rules={[{ required: true }]}
                >
                  <MultiCurrencyInput
                    form={form}
                    fieldName={"subscriberPrice"}
                  ></MultiCurrencyInput>
                </Form.Item>
                <Form.Item
                  name="nonSubscriberPrice"
                  label="Price for Non Subscribers"
                  className="form-item-selector"
                  rules={[{ required: true }]}
                >
                  <MultiCurrencyInput
                    form={form}
                    fieldName={"nonSubscriberPrice"}
                  ></MultiCurrencyInput>
                </Form.Item>
              </>
            )}
          </Card>
          <Card title="Stream" className="form-card">
            <Form.Item
              name="duration"
              label="Duration"
              className="form-item-selector"
              rules={[{ required: true }]}
            >
              <InputNumber placeholder="Enter duration in seconds" min={0} />
            </Form.Item>
            <Form.Item
              name="skipIntroStart"
              label="Skip Intro Start"
              className="form-item-selector"
            >
              <InputNumber placeholder="Enter start time in seconds" min={0} />
            </Form.Item>
            <Form.Item
              name="skipIntroEnd"
              label="Skip Intro End"
              className="form-item-selector"
            >
              <InputNumber placeholder="Enter end time in seconds" min={0} />
            </Form.Item>
            <Space direction="horizontal">
              <span>Download</span>
              <Form.Item
                name="download"
                label="Download"
                className="form-item-selector"
                rules={[{ required: false }]}
                valuePropName="checked"
                noStyle
              >
                <Switch />
              </Form.Item>
            </Space>
          </Card>
          {superAdmin && (
            <Form.Item name="comments" label="Comments">
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name={"popular"}
          >
            <Checkbox onChange={(e) => setPopular(e.target.checked)}>Popular</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageMovies;
