import { useState } from 'react';
import { Row, Select, Table, DatePicker, Space } from 'antd';

const Rewards = () => {
  const { Column } = Table;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [rewardsList, setRewardsList] = useState(null);

  return (
    <div className="manage-orders-wrapper">
      <DatePicker picker="month" />

      <Table style={{ marginTop: '30px' }}>
        <Column title="Date" dataIndex="date" key="date" align="left" />
        <Column
          title="Total # of Referrals"
          dataIndex="total"
          key="total"
          align="left"
        />
        <Column
          title="Points Given"
          dataIndex="points"
          key="points"
          align="right"
        />
        <Column
          title="Points Redeemed"
          dataIndex="redeemed"
          key="redeemed"
          align="right"
        />
      </Table>
    </div>
  );
};

export default Rewards;
