import {
  Select,
  Table,
  Input,
  Space,
  Modal,
  Form,
  InputNumber,
  notification,
  Switch,
  Card,
  Tag,
  Radio,
  Button,
  Popover,
  message,
} from "antd";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import * as tus from "tus-js-client";
import moment from "moment";

import { Context } from "../../context";
import { Fetch, Post, Put, Delete } from "../../fetch";
import { convertIdToKey, countryCodes } from "../../utils";
import UploadBanners from "../UploadBanners/index";

const { Option } = Select;
const { Column } = Table;
const { confirm } = Modal;
const { TextArea } = Input;

const ManageVideos = () => {
  const { apiUrl, token, transcodeUrl, superAdmin } = useContext(Context);

  const [videos, setvideos] = useState([]);
  const [videosMaster, setvideosMaster] = useState([]);
  const [video, setvideo] = useState();
  const [actors, setActors] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [contentExists, setContentExists] = useState(false);
  const [counter, setCounter] = useState(0);
  const [directors, setDirectors] = useState([]);
  const [producers, setProducers] = useState([]);
  const [singers, setSingers] = useState([]);
  const [lyricists, setLyricists] = useState([]);
  const [genres, setGenres] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [listType, setListType] = useState('allow_all');
  const [allowList, setAllowList] = useState([]);

  const [cardImageLoading, setCardImageLoading] = useState(false);
  const [detailImageLoading, setDetailImageLoading] = useState(false);
  const [trailerImageLoading, setTrailerImageLoading] = useState(false);
  const [logoImageLoading, setLogoImageLoading] = useState(false);

  const [cardImageUrl, setCardImageUrl] = useState();
  const [detailImageUrl, setDetailImageUrl] = useState();
  const [trailerImageUrl, setTrailerImageUrl] = useState();
  const [logoImageUrl, setLogoImageUrl] = useState(false);
  const [featured, setFeatured] = useState(false);


  const cardImageUploadButton =
    (
      <div>
        {cardImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload (300x400)</div>
      </div>
    )

  const detailImageUploadButton = (
    <div>
      {detailImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload (1500x500)</div>
    </div>
  );

  const trailerImageUploadButton = (
    <div>
      {trailerImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload (512x288)</div>
    </div>
  );

  const logoImageUploadButton = (
    <div>
      {logoImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload (400x300)</div>
    </div>
  );

  const beforeImageUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Only JPG and PNG files are allowed");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error("Image must be smaller than 1M");
    }

    return isJpgOrPng && isLt2M;
  };

  const handleCardImageUploadStatusChange = (info) => {
    if (info.file.status === "uploading") {
      setCardImageLoading(true);
      return;
    }

    if (info.file.status === "done") {
      setCardImageLoading(false);
      setCardImageUrl(info.file.response.url + '/desktopCard');
      form.setFieldsValue({ cardImage: info.file.response.url });
    }
  };


  const handleLogoImageUploadStatusChange = (info) => {
    if (info.file.status === "uploading") {
      setLogoImageLoading(true);
      return;
    }

    if (info.file.status === "done") {
      setLogoImageLoading(false);
      setLogoImageUrl(info.file.response.url + '/public');
      form.setFieldsValue({ logoImage: info.file.response.url + '/public' });
    }
  };

  const handleDetailImageUploadStatusChange = (info) => {
    if (info.file.status === "uploading") {
      setDetailImageLoading(true);
      return;
    }

    if (info.file.status === "done") {
      setDetailImageLoading(false);
      setDetailImageUrl(info.file.response.url + '/desktopDetail');
      form.setFieldsValue({ detailImage: info.file.response.url });
    }
  };

  const handleTrailerImageUploadStatusChange = (info) => {
    if (info.file.status === "uploading") {
      setTrailerImageLoading(true);
      return;
    }

    if (info.file.status === "done") {
      setTrailerImageLoading(false);
      setTrailerImageUrl(info.file.response.url);
      form.setFieldsValue({
        trailerImage: info.file.response.url,
      });
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    Fetch(`${apiUrl}/videos`, token, (result) => {
      const videos = convertIdToKey(result.videos);
      videos.forEach((s) => {
        if (!s.videoUploadDateTime) {
          s.videoUploadDateTime = "NA";
        } else {
          s.videoUploadDateTime = moment(s.videoUploadDateTime).format(
            "DD/MM/YYYY hh:mm A"
          );
        }
      });
      const unpublished = sessionStorage.getItem("unpublished");
      if (unpublished) {
        const result = videos.filter((s) => s.availability === "unpublished");
        setvideos(result);
        setvideosMaster(result);
      } else {
        setvideos(videos);
        setvideosMaster(videos);
      }
    });

    Fetch(`${apiUrl}/artists`, token, (result) => {
      setActors(result.artists);
    });

    Fetch(`${apiUrl}/genres`, token, (result) => {
      setGenres(result.genres);
    });

    Fetch(`${apiUrl}/languages`, token, (result) => {
      setLanguages(result.languages);
    });

    Fetch(`${apiUrl}/mediaHouses`, token, (result) => {
      setDirectors(result.directors);
      setProducers(result.producers);
      setSingers(result.singers);
      setLyricists(result.lyricists);
    });
  }, [apiUrl, token, counter]);


  const showEditModal = (record) => {
    setvideo(record);
    setContentExists(record.playbackUrl ? true : false);
    setModalVisible(true);
    form.setFieldsValue(record);
    let values = record.allowedCountries
    form.setFieldValue('listType', record.listType)
    setListType(record.listType)
    setFeatured(record.featured);
    let countries = []
    if (record.listType === 'allow') {
      form.setFieldValue('countryMap', values)
    }
    if (record.listType === 'disallow') {
      for (const i in countryCodes) {
        if (!values.includes(i)) {
          countries.push(i)
        }
      }
      form.setFieldValue('countryMap', countries)
      countries = []
    }
  };

  const handleSubmit = async () => {
    setConfirmLoading(true);
    try {
      const values = await form.validateFields();
      console.log('validate fields', values.countryMap)
      if (listType === 'allow') {
        for (const i in countryCodes) {
          if (values.countryMap.includes(i)) allowList.push(i)
        }
        console.log(allowList)
      }
      if (listType === 'disallow') {
        for (const i in countryCodes) {
          if (!values.countryMap.includes(i)) allowList.push(i)
        }
        console.log(allowList)
      }
      if (listType === 'allow_all') {
        for (const i in countryCodes) {
          allowList.push(i)
        }
      }
      Put(
        `${apiUrl}/videos/${video.key}`,
        token,
        {
          ...values,
          listType: listType,
          allowedCountries: allowList,
          featured: featured,
        },
        (result) => {
          if (result.success) {
            form.resetFields();

            notification.success({
              message: "Success",
              description: "Video edited",
            });
            setModalVisible(false);
            setCounter(counter + 1);
            setAllowList([])
          }
        },
        () => {
          notification.error({
            message: "Error",
            description: "Please check console for more details",
          });
        }
      );
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Error",
        description: "Required fields incorrect/missing",
      });
      setAllowList([])
    } finally {
      setConfirmLoading(false);
      setAllowList([])
    }
  };

  const handleUploadChange = (e) => {
    const file = e.target.files[0];

    setUploading(true);

    const upload = new tus.Upload(file, {
      endpoint: `${transcodeUrl}/files`,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onError: (error) => {
        console.log("Failed because: " + error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setUploadProgress(percentage);
        console.log(bytesUploaded, bytesTotal, percentage + "%");
      },
      onSuccess: () => {
        Post(
          `${transcodeUrl}/submitJob`,
          token,
          {
            id: video.key,
            loc: upload.url.split("/").slice(-1)[0],
            media_type: "videos",
          },
          (result) => {
            console.log(result);
          },
          () => {
            console.error(`Error transcoding content`);
          }
        );
        console.log("Download %s from %s", upload.file.name, upload.url);
      },
    });

    upload.start();
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Are you sure you want to delete this Video?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        Delete(
          `${apiUrl}/videos/${record.key}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: "Succes",
                description: "Video deleted successfully",
              });
              setCounter(counter + 1);
            }
          },
          () => {
            notification.error({
              message: "Error deleting Video",
              description: "Please check console for more details",
            });
          }
        );
      },
    });
  };

  const handleSearchChange = (videoId) => {
    Fetch(`${apiUrl}/videos/${videoId}`, token, (result) => {
      const video = convertIdToKey([result]);
      video.forEach((video) => {
        if (!video.videoUploadDateTime) {
          video.videoUploadDateTime = "NA";
        } else {
          video.videoUploadDateTime = moment(video.videoUploadDateTime).format(
            "DD/MM/YYYY hh:mm A"
          );
        }
      });
      setvideos(video);
    });
  };

  const handleFeaturedToggle = (checked) => {
    setFeatured(checked);
  }

  return (
    <div className="manage-episodes-wrapper">
      <Select
        placeholder="Search Video"
        showSearch
        allowClear
        onChange={handleSearchChange}
        onClear={() => setvideos(videosMaster)}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        {videosMaster.map((m) => (
          <Option value={m.key}>{m.title}</Option>
        ))}
      </Select>
      <Table dataSource={videos}>
        <Column title="Title" dataIndex="title" key="name" align="center" />
        <Column
          title="Featured"
          key="featured"
          align="center"
          render={(_, record) =>
            record.featured ? <CheckCircleFilled /> : <CloseCircleOutlined />
          }
        />
        <Column
          title="Genres"
          dataIndex="genre"
          key="genre"
          align="center"
          render={(values) => (
            <>
              {values.map((v) => (
                <Tag color="blue">{v}</Tag>
              ))}
            </>
          )}
        />
        <Column title="Model" dataIndex="model" key="model" align="center" />
        <Column
          title="Availability"
          dataIndex="availability"
          key="availability"
          align="center"
        />
        <Column
          title="Content Exists"
          key="trailer"
          align="center"
          render={(_, record) =>
            record.playbackUrl ? <CheckCircleFilled /> : <CloseCircleOutlined />
          }
        />
        <Column
          title="Video Uploaded Date"
          dataIndex="videoUploadDateTime"
          key="videoUploadDateTime"
          align="center"
        />
        <Column
          title="Actions"
          key="action"
          align="center"
          render={(text, record) => {
            if (superAdmin || record.availability === "unpublished") {
              if (record.comments) {
                return (
                  <Space size="middle">
                    <Popover
                      content={record.comments}
                      title="Admin Comments"
                      trigger="click"
                    >
                      <Button>View Comments</Button>
                    </Popover>
                    <Button onClick={() => showEditModal(record)}>Edit</Button>
                    <Button
                      onClick={() => {
                        confirmDelete(record);
                      }}
                    >
                      Delete
                    </Button>
                  </Space>
                );
              }
              return (
                <Space size="middle">
                  <Button onClick={() => showEditModal(record)}>Edit</Button>
                  <Button
                    onClick={() => {
                      confirmDelete(record);
                    }}
                  >
                    Delete
                  </Button>
                </Space>
              );
            } else {
              return "-";
            }
          }}
        />
      </Table>
      <Modal
        title="Edit Video"
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Update"
        onCancel={() => setModalVisible(false)}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input size="middle" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="slug"
            label="Slug in url"
            rules={[{ required: true }]}
          >
            <Input size="middle" />
          </Form.Item>
          <Form.Item
            name="language"
            label="Language"
            rules={[{ required: true }]}
          >
            <Select placeholder="Choose a language" showSearch>
              {languages.map((language) => (
                <Option value={language.name}>{language.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="genre"
            label="Genre / Category"
            rules={[{ required: true }]}
          >
            <Select placeholder="Choose a genre" mode="multiple" showSearch>
              {genres.map((genre) => (
                <Option value={genre.name}>{genre.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="maturity"
            label="Maturity"
            rules={[{ required: true }]}
          >
            <Select placeholder="Choose maturity">
              <Option value="U">U (Universal)</Option>
              <Option value="U/A 7+">U/A 7+ (For Ages 7 and Above)</Option>
              <Option value="U/A 13+">U/A 13+ (For Ages 13 and Above)</Option>
              <Option value="U/A 16+">U/A 16+ (For Ages 16 and Above)</Option>
              <Option value="A">A (Adults - Ages 18 and Above)</Option>
            </Select>
          </Form.Item>
          <Form.Item name="year" label="Year" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="metaTitle"
            label="metaTitle"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="metaDescription"
            label="metaDescription"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="metaKeywords"
            label="metaKeywords"
            rules={[{ required: true }]}
          >
            <Select
              mode="tags"
              style={{
                width: "100%",
              }}
              tokenSeparators={["Enter"]}
            ></Select>
          </Form.Item>
          <Card title="Cast &amp; Crew" className="form-card">
            <Form.Item
              name="actors"
              label="Actors"
              className="form-item-selector"
              rules={[{ required: true }]}
            >
              <Select showSearch mode="multiple" className="form-card-selector">
                {actors.map((actor) => (
                  <Option key={actor.key} value={actor.name}>
                    {actor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="directors"
              label="Directors"
              className="form-item-selector"
            >
              <Select showSearch mode="multiple" className="form-card-selector">
                {directors.map((director) => (
                  <Option key={director.key} value={director.name}>
                    {director.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="producers"
              label="Producers"
              className="form-item-selector"
            >
              <Select showSearch mode="multiple">
                {producers.map((producer) => (
                  <Option key={producer.key} value={producer.name}>
                    {producer.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Card>
          <Form.Item
            name="cardImage"
            label="Card Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"cardImage"}
              type="video"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="trailerImage"
            label="Wide Image (for continue watch in web)"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"trailerImage"}
              type="video"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="detailImage"
            label="Detail Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={`detailImage`}
              type="video"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="bannerImage"
            label="Banner Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={`bannerImage`}
              type="video"
              updateForm={form}
            />
          </Form.Item>
          {/* <Form.Item
            name="logoImage"
            label="Logo Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={`logoImage`}
              type="video"
              updateForm={form}
            />
          </Form.Item> */}
          <Form.Item
            name="featured"
            label="Featured?"
          >
            <Switch checked={featured} onChange={handleFeaturedToggle} />
          </Form.Item>
          <Form.Item
            name="duration"
            label="Duration"
            className="form-item-selector"
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="Enter duration in seconds" />
          </Form.Item>
          <Form.Item name='listType' label={'Content access'} rules={[{ 'required': true }]} initialValue={listType}>
            <Radio.Group
              className="content-type-option"
              value={listType}
              onChange={(e) => setListType(e.target.value)}
              defaultValue={'allow_all'}
            >
              <Radio value="allow_all">Allow Globally</Radio>
              <Radio value="allow">Allow List</Radio>
              <Radio value="disallow">Deny List</Radio>
            </Radio.Group>
          </Form.Item>

          {['allow', 'disallow'].includes(listType) && (
            <Form.Item
              name={`countryMap`}
              label={'Countries'}
              rules={[{ required: true }]}
              style={{ width: '100%' }}
            >
              <Select
                showSearch
                mode="multiple"
                className="form-card-selector"
                style={{ width: '100%' }}
              >
                {Object.entries(countryCodes).map(([code, name]) => (
                  <Option key={code} value={code} >
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>)}
          <Form.Item label="Model" name="model">
            <Radio.Group>
              <Radio value="subscription">Subscription</Radio>
              <Radio value="free">Free</Radio>
            </Radio.Group>
          </Form.Item>
          {superAdmin && (
            <Form.Item
              name="availability"
              label="Availability"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="perpetual">Perpetual</Radio>
                <Radio value="unpublished">Unpublished</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {superAdmin && (
            <Form.Item name="comments" label="Comments">
              <Input />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default ManageVideos;
