import { Card, Upload, message, Col, Popconfirm, Tag, } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../context'

const imgs = {
  cardImage: {
    width: 300,
    height: 400,
    ratio: "3:4",
    styleWidth: 3 * 50,
    styleHeight: 4 * 50,
    aspect: 0.75
  },
  cardImageMovie: {
    width: 300,
    height: 400,
    ratio: "3:4",
    styleWidth: 3 * 50,
    styleHeight: 4 * 50,
    aspect: 0.75

  },
  cardImageSeries: {
    width: 300,
    height: 400,
    ratio: "3:4",
    styleWidth: 3 * 50,
    styleHeight: 4 * 50,
    aspect: 0.75
  },
  cardImageSong3_4: {
    width: 300,
    height: 400,
    ratio: "3:4",
    styleWidth: 3 * 50,
    styleHeight: 4 * 50,
    aspect: 0.75
  },
  cardImageEpisode: {
    width: 450,
    height: 250,
    ratio: "9:5",
    styleWidth: 9 * 40,
    styleHeight: 5 * 40,
    aspect: 1.8
  },
  cardImageSong: {
    width: 450,
    height: 250,
    ratio: "9:5",
    styleWidth: 9 * 40,
    styleHeight: 5 * 40,
    aspect: 1.8
  },
  bannerImage: {
    width: 3200 / 2,
    height: 900 / 2,
    ratio: "32:9",
    styleWidth: 32 * 10,
    styleHeight: 9 * 10,
    aspect: 3.5
  },
  mobileImage: {
    width: 1024,
    height: 576,
    ratio: "16:9",
    styleWidth: 16 * 20,
    styleHeight: 9 * 20,
    aspect: 1.7
  },
  logoImage: {
    width: 400,
    height: 300,
    ratio: "4:3",
    styleWidth: 10 * 20,
    styleHeight: 8 * 20,
    aspect: 1.3
  },
  detailImage: {
    width: 1500,
    height: 500,
    ratio: "3:1",
    styleWidth: 3 * 100,
    styleHeight: 1 * 100,
    aspect: 3
  },
  trailerImage: {
    width: 512,
    height: 288,
    ratio: "16:9",
    styleWidth: 16 * 20,
    styleHeight: 9 * 20,
    aspect: 1.7
  },
  voucherImage: {
    width: 500,
    height: 400,
    ratio: "5:4",
    styleWidth: 5 * 50,
    styleHeight: 4 * 50,
    aspect: 1.25
  },
};

const UploadBanners = (props) => {
  const { token, apiUrl } = useContext(Context);
  //Natural dimension of image
  const [dim, setDim] = useState({ dim: "0x0", show: false, aspect: null });
  const [imageUrl, setImageUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const types = [
    "movie",
    "movies",
    "series",
    "episode",
    "album",
    "albums",
    "music",
    "song",
    "songs",
  ];
  const type = types.includes(props.type);
  const variantName = props.name;
  const uxName = props.name;
  const handleImageUploadStatusChange = (info) => {
    if (info.file.status === "uploading") {
      setImageLoading(true);
      return;
    }

    if (info.file.status === "done") {
      let url = info.file.response.url
      setImageLoading(false);
      setImageUrl(url);
      let v = variantName;
      // console.log('variant name - ', v);
      props.updateForm.setFieldsValue({ [v]: url });
    }
  };

  const getAspectRatio = (a, b) => {
    return b == 0 ? a : getAspectRatio(b, a % b);
  };

  const beforeImageUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    let showsize = false;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return isJpgOrPng
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return isLt2M
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      let image = new Image();
      const _loadedImageUrl = event.target.result;
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        // set image width and height to your state here
        let naturalWidth = image.naturalWidth;
        let naturalHeight = image.naturalHeight;
        let ratio = getAspectRatio(naturalWidth, naturalHeight);
        setDim({
          show: true,
          dim: `${naturalWidth}x${naturalHeight}`,
          aspect: `${naturalWidth / ratio}:${naturalHeight / ratio}`,
        });
        message.info(
          `Uploading ${image.naturalWidth}x${image.naturalHeight} image`
        );
      });
    });
    return isJpgOrPng && isLt2M;
  };

  const onImgLoad = () => {
    var img = document.getElementById(`${uxName}`);
    let naturalWidth = img.naturalWidth;
    let naturalHeight = img.naturalHeight;
    let ratio = getAspectRatio(naturalWidth, naturalHeight);
    setDim({
      show: true,
      dim: `${naturalWidth}x${naturalHeight}`,
      aspect: `${naturalWidth / ratio}:${naturalHeight / ratio}`,
    });
  };

  useEffect(() => {
    let imgUrl = props.updateForm.getFieldValue(variantName);
    // console.log(props.name, '-', imgUrl)
    let url = null
    if (imgUrl) {
      url = imgUrl
    }
    if (props.name === 'cardImage') url = url + '/desktopCard'
    else if (props.name === 'trailerImage') url = url + '/desktopBanner'
    else if (props.name === 'detailImage') url = url + '/desktopDetail'
    else if (props.name === 'logoImage' || props.name === 'voucherImage') url = url + '/public'
    else if (props.name === 'bannerImage' && props.type === 'series') url = url + '/public'
    // else if (props.name === 'bannerImage' || props.name === 'cardImageSeries') url = url + '/desktopBanner'
    if (imgUrl) setImageUrl(url);
  }, [props]);

  return (
    <Col span="24">
      <Card>
        <Col span={24} style={{ marginBottom: "10px" }}>
          {false ? (
            <Popconfirm
              placement="top"
              title={
                <>
                  <p>
                    Are you sure you want to delete Media file <br />
                    once deleted you can't retrieve ?{" "}
                  </p>
                </>
              }
              onConfirm={() => {}}
              okText={"Confirm Delete"}
              cancelText="Cancel"
              okType="danger"
              icon={null}
            >
              <CloseCircleFilled
                size={"large"}
                style={{
                  position: "absolute",
                  float: "left",
                  top: "0px",
                  left: `${imgs[uxName].styleWidth}px`,
                  fontSize: "20px",
                  borderRadius: "50px",
                  zIndex: 20,
                }}
              ></CloseCircleFilled>
            </Popconfirm>
          ) : null}
          <Upload
            listType="picture"
            showUploadList={false}
            action={`${apiUrl}/uploads/cloudflare`}
            headers={{
              authorization: `Bearer ${token}`,
            }}
            beforeUpload={beforeImageUpload}
            onChange={handleImageUploadStatusChange}
          >
            {imageUrl ? (
              <div style={{ cursor: "pointer" }}>
                <img
                  src={imageUrl}
                  id={`${uxName}`}
                  title={"Click to replace image"}
                  alt={`${imgs[uxName].width}x${imgs[uxName].height} image exists ${imageUrl}`}
                  width={imgs[uxName].styleWidth}
                  height={imgs[uxName].styleHeight}
                  onLoad={() => onImgLoad()}
                ></img>
                <br />
                {dim.show ? (
                  `${imgs[uxName].ratio}` === dim.aspect ? (
                    <Tag style={{ marginTop: 25 }} color={"success"}>
                      Found Size : {dim.dim} , Ratio : {dim.aspect}
                    </Tag>
                  ) : (
                    <>
                      <Tag style={{ marginTop: 25 }} color={"processing"}>
                        {`Require Size : ${imgs[uxName].width}x${imgs[uxName].height}, Ratio ${imgs[uxName].ratio}`}
                      </Tag>
                      <br />
                      <Tag style={{ marginTop: 10 }} color={"error"}>
                        {`Found Size : ${dim.dim}, Ratio : ${dim.aspect}`}
                      </Tag>{" "}
                    </>
                  )
                ) : null}
              </div>
            ) : (
              <Card
                style={{
                  backgroundColor: "#eee",
                  width: `${imgs[uxName].styleWidth}px`,
                  height: `${imgs[uxName].styleHeight}px`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "14px",
                  color: "#999",
                  textAlign: "center",
                }}
              >
                <p>
                  <b>
                    Aspect ratio: {imgs[uxName].ratio} <br />
                    {imgs[uxName].width} x {imgs[uxName].height} <br />
                    Click to Upload{" "}
                  </b>
                </p>
              </Card>
            )}
          </Upload>
        </Col>
        <Col span={24} style={{ marginBottom: "10px" }}></Col>
      </Card>
    </Col>
  );
};

export default UploadBanners;