import {
  Form,
  Modal,
  Select,
  Table,
  Radio,
  Input,
  Button,
  Space,
  notification,
  InputNumber,
  Switch
} from 'antd'
import { useContext, useEffect, useState } from 'react'
import { PlusCircleTwoTone, MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { Fetch, Post, Put, Delete } from '../../fetch'
import { convertIdToKey, removeUnpublished } from '../../utils'
import { Context } from '../../context'

const { Option } = Select
const { Column } = Table
const { confirm } = Modal

const ManagePlaylists = () => {
  const { apiUrl, token } = useContext(Context)

  const [page, setPage] = useState()
  const [playlists, setPlaylists] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  const [movies, setMovies] = useState([])
  const [series, setSeries] = useState([])
  const [videos, setvideos] = useState([])
  const [playlistId, setPlaylistId] = useState()
  const [playlistType, setPlaylistType] = useState('default')
  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const [help, setHelp] = useState(null)

  const [form] = Form.useForm()

  useEffect(() => {
    if (page) {
      Fetch(`${apiUrl}/playlists?page=${page}`, token, result => {
        const playlists = convertIdToKey(result.playlists).sort((a, b) => a.position - b.position)
        setPlaylists(playlists)
      })
    }

    Fetch(`${apiUrl}/movies`, token, (result) => {
      const movies = removeUnpublished(convertIdToKey(result.movies))
      setMovies(movies)
    })

    Fetch(`${apiUrl}/shows`, token, (result) => {
      const series = removeUnpublished(convertIdToKey(result.series))
      setSeries(series)
    })

    Fetch(`${apiUrl}/videos`, token, (result) => {
      const videos = removeUnpublished(convertIdToKey(result.videos))
      setvideos(videos)
    })
  }, [apiUrl, token, page, counter])

  const handleSubmit = async () => {
    setConfirmLoading(true)
    try {
      const fields = await form.validateFields()
      if (playlistType === 'default') {
        const duplicates = fields.content.filter((item, index) => fields.content.indexOf(item) !== index)
        if (duplicates.length > 0) {
          notification.error({
            message: 'Manage Playlists Error',
            description: 'Content has duplicates'
          })
          setConfirmLoading(false);
        }
      }
      if (playlistType === 'hero' && error) {
        notification.error({
          message: 'Hero Banner Error',
          description: help
        });
        setConfirmLoading(false);
        return;
      }

      const fn = modalType === 'Add' ? Post : Put
      fn(
        modalType === 'Add' ? `${apiUrl}/playlists` : `${apiUrl}/playlists/${playlistId}`,
        token,
        { ...fields, page },
        result => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: 'Playlists updated'
            })
          }
          form.resetFields()
          setModalVisible(false)
          setConfirmLoading(false)
          setCounter(counter + 1)
        },
        () => {
          setConfirmLoading(false);
          notification.error({
            message: 'Manage Playlists Error',
            description: 'Check console for more details'
          })
        }
      )
    } catch (err) {
      setConfirmLoading(false);
    }
  }

  const showEditModal = (record) => {
    setModalType("Edit")
    setPlaylistId(record.key)
    setPlaylistType(record.playlistType)
    form.setFieldsValue(record)
    setModalVisible(true)
  }

  const showConfirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this playlist?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        Delete(
          `${apiUrl}/playlists/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Playlist deleted successfully'
              })
              setCounter(counter + 1)
            }
          },
          () => {
            notification.error({
              message: 'Error deleting playlist',
              description: 'Please check console for more details'
            })
          }
        )
      }
    })
  }

  const filterMaturity = (video, page) => {
    if (page.includes('kids')) {
      return video.maturity === 'U';
    }
    return true
  }

  const checkValidPosition = (v) => {
    if (playlistType === 'hero') {
      if (v < 3 && v !== null) {
        setError('error');
        setHelp('Hero Banners must be placed from position 3');
        return
      }
      else if (v >= 3) {
        const heroPlaylists = playlists.filter((p) => p.playlistType === 'hero')

        heroPlaylists.forEach((p) => {

          if (p.position === v && modalType === 'Add') {
            setError('error');
            setHelp('Hero banner exists in that position');
            return;
          } else if ((p.position - 3) < v && (p.position + 3) > v) {
            setError('error');
            setHelp('Hero Banners must be atleast 2 playlists apart');
            return;
          } else {
            setError(null);
            setHelp(null);
            return;
          }
        })
      } else {
        setError(null);
        setHelp(null);
        return;
      }
    }
  }
  return (
    <div className="manage-episodes-wrapper">
      <Select
        placeholder="Select Page"
        showSearch
        onChange={(page) => setPage(page)}
      >
        <Option value="home">Home</Option>
        {/* <Option value="movies">Movies</Option> */}
        <Option value="videos">Videos</Option>
        <Option value="shows">Shows</Option>
        {/* <Option value="search">Search</Option> */}
      </Select>
      <Table dataSource={playlists}>
        <Column title="Name" dataIndex="name" key="name" align="center" />
        <Column
          title="Position"
          dataIndex="position"
          key="position"
          align="center"
        />
        <Column
          title="Actions"
          key="action"
          align="center"
          render={(text, record) => (
            <Space size="middle">
              <a
                onClick={() => {
                  showEditModal(record);
                }}
              >
                Edit
              </a>
              {page !== 'search' && <a onClick={() => showConfirmDelete(record.key)}>Delete</a>}
            </Space>
          )}
        />
      </Table>
      {page !== 'search' && <PlusCircleTwoTone
        twoToneColor="#FF0091"
        style={{ fontSize: '48px' }}
        className="add-series-btn"
        onClick={() => {
          setModalVisible(true);
          setModalType('Add');
          form.resetFields();
        }}
      />}
      <Modal
        title={modalType + ' Playlist'}
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => { setModalVisible(false); setPlaylistType('default'); setError(null); setHelp(null); }}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name={'playlistType'}
            label="Playlist Type"
            initialValue={'default'}
            required
          >
            <Select defaultValue={'default'} onChange={setPlaylistType}>
              <Option value='default'>Default</Option>
              <Option value='hero'>Hero Banner</Option>
            </Select>
          </Form.Item>
          {playlistType === 'default' && (
            <Form.Item name="name" label="Name" rules={[{ required: true }]} disabled={page === 'search'}>
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name="position"
            label="Position"
            rules={[{ required: true }]}
            disabled={page === 'search'}
            validateStatus={error}
            hasFeedback
            // help={playlistType === 'hero' ? 'Hero Banner must be placed starting from position 3' : null}
            help={help}
          >
            <InputNumber onChange={(v) => { checkValidPosition(v) }} style={{ width: '100%' }} controls={false} />
          </Form.Item>
          {playlistType === 'default' && (<Form.List
            name="content"
            rules={[
              {
                validator: async (_, content) => {
                  if (!content || content.length < 1) {
                    return Promise.reject(
                      new Error('At least 1 item required')
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? 'Content' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Please select item or remove this field',
                        },
                      ]}
                      noStyle
                    >
                      <Select
                        showSearch
                        className="form-card-selector"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        style={{ width: '100%' }}
                      >
                        {(['home-kids', 'home'].includes(page)) && (
                          <>
                            {/* {movies.filter(video => filterMaturity(video, page)).map((movie) => (
                              <Option
                                key={movie.key}
                                value={`movie|${movie.key}`}
                              >
                                {movie.title}
                              </Option>
                            ))} */}
                            {series.filter(video => filterMaturity(video, page)).map((s) => (
                              <Option key={s.key} value={`series|${s.key}`}>
                                {s.title}
                              </Option>
                            ))}
                            {videos.filter(video => filterMaturity(video, page)).map((video) => (
                              <Option
                                key={video.key}
                                value={`video|${video.key}`}
                              >
                                {video.title}
                              </Option>
                            ))}
                          </>
                        )}
                        {/* {['movies', 'movies-kids'].includes(page) &&
                          movies.filter(video => filterMaturity(video, page)).map((movie) => (
                            <Option key={movie.key} value={movie.key}>
                              {movie.title}
                            </Option>
                          ))} */}
                        {['shows', 'shows-kids'].includes(page) &&
                          series.filter(video => filterMaturity(video, page)).map((s) => (
                            <Option key={s.key} value={s.key}>
                              {s.title}
                            </Option>
                          ))}
                        {['videos', 'videos-kids'].includes(page) &&
                          videos.filter(video => filterMaturity(video, page)).map((video) => (
                            <Option key={video.key} value={video.key}>
                              {video.title}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Add item
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>)}
          {playlistType === 'hero' && (
            <Form.Item
              name={'hero'}
              label='Content'
              required
            >
              <Select
                showSearch
                className="form-card-selector"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                style={{ width: '100%' }}
                onChange={(v) => { setName(v); console.log(v); }}
              >
                {['shows', 'home'].includes(page) &&
                  series.filter(video => filterMaturity(video, page)).map((s) => (
                    <Option key={s.key} value={s.key}>
                      {s.title}
                    </Option>
                  ))}
                {['videos', 'home'].includes(page) &&
                  videos.filter(video => filterMaturity(video, page)).map((video) => (
                    <Option
                      key={video.key}
                      value={video.key}
                    >
                      {video.title}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
}

export default ManagePlaylists
