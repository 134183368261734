import {
    Form,
    Button,
    Input,
    Modal,
    Table,
    notification,
    InputNumber,
    Space,
    Select,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import {
    PlusCircleTwoTone,
    MinusCircleOutlined,
    PlusOutlined,
    CloudDownloadOutlined,
} from '@ant-design/icons';

import './styles.css';

import { Context } from '../../context';
import { Fetch, Post, Put, Delete } from '../../fetch';
import { convertIdToKey, handleExportClick } from '../../utils';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
const { Column } = Table;

const ManagePlans = () => {
    const { token, apiUrl } = useContext(Context);

    const [plans, setPlans] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [allResellers, setAllResellers] = useState([]);

    const [planId, setPlanId] = useState();

    const { Option } = Select;

    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();

    const getAllResellers = () => {
        Fetch(`${apiUrl}/resellers`, token, (result) => {
            if ('resellers' in result) setAllResellers(result.resellers);
        });
    };

    const resellerOnChange = (e) => {
        console.log('Reseller on change - ', e);
    };

    useEffect(() => {
        Fetch(`${apiUrl}/plans/admin`, token, (result) => {
            const plans = convertIdToKey(result.plans);

            getAllResellers();

            setPlans(plans.filter(x => x.reseller));
        });

        if (planId) {
            Fetch(`${apiUrl}/plans/${planId}`, token, (result) => {
                const { ...others } = result;

                form.setFieldsValue(others);
            });
        }
    }, [apiUrl, token, confirmLoading, planId]);

    const handleSubmit = async () => {
        let resellerObj = {};
        try {
            await setConfirmLoading(true);
            await form.validateFields();

            let resid = form.getFieldValue('reseller');
            let resPric = form.getFieldValue('resellerPrice');
            let resCred = form.getFieldValue('resellerCredits');

            if (resid && resPric && resCred) {
                resellerObj = {
                    reseller: resid,
                    resellerPrice: resPric,
                    resellerCredits: resCred,
                };
            }
            Post(
                `${apiUrl}/resellers/${resid}/plans`,
                token,
                {
                    name: form.getFieldValue('name'),
                    price: form.getFieldValue('price'),
                    internationalPrice: form.getFieldValue('internationalPrice'),
                    duration: form.getFieldValue('duration'),
                    features: form.getFieldValue('features'),
                    ...resellerObj,
                },
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Plan created',
                        });
                    }
                    form.resetFields();
                    setModalVisible(false);
                },
                () => {
                    notification.error({
                        message: 'Add Plan Error',
                        description: 'Check console for more details',
                    });
                }
            );
        } catch (err) {
            console.error(err);
            notification.error({
                message: 'Add Plan Error',
                description: 'Required fields are incorrect/missing',
            });
        } finally {
            setConfirmLoading(false);
        }
    };

    const getPlanDetails = async (id) => {
        Fetch(`${apiUrl}/plans/${id}`, token, (result) => {
            const { ...others } = result;
            console.log('hello');

            formEdit.setFieldsValue(others);
            setPlanId(id);

            // setDetailImageUrl(result.detailImage)
            // setTrailerExists(result.trailer ? true : false)
        });
    };

    const handleEditSubmit = async () => {
        let resellerObj = {};
        try {
            await setConfirmLoading(true);
            const values = await formEdit.validateFields();
            let resid = formEdit.getFieldValue('reseller');
            let resPric = formEdit.getFieldValue('resellerPrice');
            let resCred = formEdit.getFieldValue('resellerCredits');

            if (resid && resPric && resCred) {
                resellerObj = {
                    reseller: resid,
                    resellerPrice: resPric,
                    resellerCredits: resCred,
                };
            }

            Put(
                `${apiUrl}/resellers/${resid}/plans/${planId}`,
                token,
                {
                    name: formEdit.getFieldValue('name'),
                    price: formEdit.getFieldValue('price'),
                    internationalPrice: formEdit.getFieldValue('internationalPrice'),
                    duration: formEdit.getFieldValue('duration'),
                    features: formEdit.getFieldValue('features'),
                    ...resellerObj,
                },
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Edited successfully',
                        });
                        formEdit.resetFields();
                        setEditModalVisible(false);
                        setPlanId(null);
                    }
                },
                () => {
                    notification.error({
                        message: 'Error updating plan',
                        description: 'Please check logs for more details',
                    });
                }
            );
        } catch (err) {
            console.error(err);
            notification.error({
                message: 'Edit Plan Error',
                description: 'Required fields are incorrect/missing',
            });
        } finally {
            setConfirmLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            await setConfirmLoading(true);
            Delete(
                `${apiUrl}/plans/${id}`,
                token,
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Plan deleted successfully',
                        });
                    }
                },
                () => {
                    notification.error({
                        message: 'Delete Plan Error',
                        description: 'Check console for more details',
                    });
                }
            );
        } finally {
            setConfirmLoading(false);
        }
    };

    return (
        <div className="manage-plans-wrapper">
            <Table dataSource={plans}>
                <Column title="ID" dataIndex="id" key="id" align="center" render={(_, record) => record.key} />
                <Column title="Name" dataIndex="name" key="name" align="center" />
                <Column title="Price" dataIndex="price" key="price" align="center" />
                {/* <Column
                    title="International Price($)"
                    dataIndex="internationalPrice"
                    key="internationalPrice"
                    align="center"
                /> */}
                <Column
                    title="Duration (days)"
                    dataIndex="duration"
                    key="duration"
                    align="center"
                />
                <Column
                    title="Plan Type"
                    dataIndex="reseller"
                    key="reseller"
                    align="center"
                    render={(text, data) => (data['reseller'] ? 'Reseller' : 'Public')}
                />
                <Column
                    title="Reseller"
                    dataIndex="resellerName"
                    key="resellerName"
                    align="center"
                    render={(text, data) => (data.reseller ? data.resellerName : 'N/A')}
                />
                <Column
                    title="Credits"
                    dataIndex="resellerCredits"
                    key="resellerCredits"
                    align="center"
                    render={(text, data) =>
                        data.reseller && data.resellerCredits ? data.resellerCredits : '0'
                    }
                />

                <Column
                    title="Actions"
                    key="action"
                    align="center"
                    render={(text, record) => (
                        <Space size="middle">
                            <a
                                onClick={() => {
                                    setPlanId(record.key);
                                    getPlanDetails(record.key);
                                    setEditModalVisible(true);
                                    console.log(record.key, 'recordkey');
                                }}
                            >
                                {<EditFilled />}
                            </a>
                            {record.isPrivate == 'public' ? (
                                <a onClick={() => handleDelete(record.key)}>
                                    {<DeleteFilled />}
                                </a>
                            ) : (
                                ''
                            )}
                        </Space>
                    )}
                />
            </Table>
            <PlusCircleTwoTone
                twoToneColor="rgb(243, 101, 35)"
                style={{ fontSize: '48px' }}
                className="add-plan-btn"
                onClick={() => {
                    form.resetFields();
                    setModalVisible(true);
                }}
            />
            <CloudDownloadOutlined
                className="export-btn"
                onClick={() => handleExportClick(apiUrl, token, 'plans')}
            />
            <Modal
                title="Add Plan"
                visible={modalVisible}
                onOk={handleSubmit}
                okText="Submit"
                onCancel={() => setModalVisible(false)}
                confirmLoading={confirmLoading}
            >
                <Form form={form} layout="vertical" initialValues={{ gender: 'M' }}>
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="price" label="Price" rules={[{ required: true }]}>
                        <InputNumber />
                    </Form.Item>
                    {/* <Form.Item
                        name="internationalPrice"
                        label="International Price"
                        rules={[{ required: true }]}
                    >
                        <InputNumber />
                    </Form.Item> */}
                    <Form.Item
                        name="duration"
                        label="Duration"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Specify number of days" />
                    </Form.Item>

                    <Form.Item name="reseller" label="Reseller Name" rules={[{ required: true }]}>
                        <Select
                            defaultValue=""
                            style={{ width: '100%' }}
                            onChange={resellerOnChange}
                            placeholder="Select Reseller"
                        >
                            {allResellers.map((itm, ind) => (
                                <Option key={itm._id} value={itm._id}>
                                    {itm.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="resellerPrice" label="Reseller Price" rules={[{ required: true }]}>
                        <InputNumber placeholder="Enter Reseller Price" />
                    </Form.Item>
                    <Form.Item name="resellerCredits" label="Reseller Credits" rules={[{ required: true }]}>
                        <InputNumber placeholder="Enter Reseller Credits" />
                    </Form.Item>

                    {/* <Form.Item
              name="isPrivate"
              valuePropName="checked"
              label="isPrivate"
              rules={[{ required: false }]}
            >
              <Checkbox value="no">Private</Checkbox>
            </Form.Item> */}

                    {/* <Form.List
                        name="features"
                        rules={[
                            {
                                validator: async (_, features) => {
                                    if (!features || features.length < 2)
                                        return Promise.reject(new Error('At least 2 features'));
                                },
                            },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        label={index === 0 ? 'Features' : ''}
                                        required={false}
                                        key={field.key}
                                    >
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: 'Please input feature or delete this field.',
                                                },
                                            ]}
                                            noStyle
                                        >
                                            <Input style={{ width: '95%' }} />
                                        </Form.Item>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-btn"
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Form.Item>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                    >
                                        Add feature
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List> */}
                </Form>
            </Modal>
            <Modal
                title="Edit Plan"
                visible={editModalVisible}
                onOk={handleEditSubmit}
                okText="Update"
                onCancel={() => {
                    formEdit.resetFields();
                    setEditModalVisible(false);
                }}
                confirmLoading={confirmLoading}
            >
                <Form form={formEdit} layout="vertical" initialValues={{ gender: 'M' }}>
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="price" label="Price" rules={[{ required: true }]}>
                        <InputNumber />
                    </Form.Item>
                    {/* <Form.Item
                        name="internationalPrice"
                        label="International Price"
                        rules={[{ required: true }]}
                    >
                        <InputNumber />
                    </Form.Item> */}
                    <Form.Item
                        name="duration"
                        label="Duration"
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder="Specify number of days" />
                    </Form.Item>

                    <Form.Item name="reseller" label="Reseller Name" rules={[{ required: true }]}>
                        <Select
                            defaultValue=""
                            style={{ width: '100%' }}
                            onChange={resellerOnChange}
                            placeholder="Select Reseller"
                        >
                            {allResellers.map((itm, ind) => (
                                <Option key={itm._id} value={itm._id}>
                                    {itm.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="resellerPrice" label="Reseller Price" rules={[{ required: true }]}>
                        <InputNumber placeholder="Enter Reseller Price" />
                    </Form.Item>
                    <Form.Item name="resellerCredits" label="Reseller Credits" rules={[{ required: true }]}>
                        <InputNumber placeholder="Enter Reseller Credits" />
                    </Form.Item>
                    {/* 
                    <Form.List
                        name="features"
                        rules={[
                            {
                                validator: async (_, features) => {
                                    if (!features || features.length < 2)
                                        return Promise.reject(new Error('At least 2 features'));
                                },
                            },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        label={index === 0 ? 'Features' : ''}
                                        required={false}
                                        key={field.key}
                                    >
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: 'Please input feature or delete this field.',
                                                },
                                            ]}
                                            noStyle
                                        >
                                            <Input style={{ width: '95%' }} />
                                        </Form.Item>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-btn"
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Form.Item>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                    >
                                        Add feature
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List> */}
                </Form>
            </Modal>
        </div>
    );
};

export default ManagePlans;
